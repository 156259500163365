import { Container, Paper, Box } from "@mui/material";
import { graphql } from "gatsby";
import React from "react";
import { useSelectedPropertyContext } from "../components/SelectedPropertyProvider";
import isNode from "is-node";

const ReleaseNotesPage = ({ data }) => {
  const { selected_locale } = useSelectedPropertyContext();
  if (isNode) {
    return null;
  }
  return (
    <Container sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
      <Box sx={{ pl: 2, pr: 2 }}>
        <div
          dangerouslySetInnerHTML={{
            __html: data.allMarkdownRemark.nodes.find(
              ({ frontmatter }) => frontmatter.locale === selected_locale
            ).html,
          }}
        />
      </Box>
    </Container>
  );
};

export const query = graphql`
  query ReleaseNotes {
    allMarkdownRemark {
      nodes {
        frontmatter {
          locale
        }
        html
        htmlAst
      }
    }
  }
`;

export default ReleaseNotesPage;
